import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Storage } from '../classes/Storage'
import { ProposalView, ApplicationUpdateData } from '../model/ProposalView'
import { map } from 'rxjs/operators'

import { ProposalApi } from '../model/ProposalApi'
import { ProposalManagement } from '../classes/ProposalManagement'
import { ProposalDecision } from '../model/ProposalDecision'

const URL = `${environment.API_URL}`
const WP_URL = `${environment.WP_URL}`

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  constructor(private http: HttpClient) {}

  updateProposal(updateData: ApplicationUpdateData): Observable<any> {
    const data = JSON.stringify({
      field_name: updateData?.fieldName,
      field_value: updateData?.fieldValue,
      hash: Storage.getHash(),
    })

    return this.http.post(`${URL}/update_proposal`, data)
  }

  getDataFromHash(hash: string): Observable<ProposalView> {
    return this.http.get(`${URL}/get_proposal/${hash}`).pipe(
      map((value: ProposalApi) => {
        return ProposalManagement.getProposalView(value)
      })
    )
  }

  sendApplication(proposalApi: ProposalApi): Observable<ProposalDecision> {
    // @ts-ignore
    return this.http.post(
      `${URL}/send_application/${Storage.getHash()}`,
      JSON.stringify(proposalApi)
    )
  }

  redirectToFirstStep(): void {
    window.location.href = WP_URL
  }
}
