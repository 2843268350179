import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { Address, ApplicationUpdateData } from '../../../model/ProposalView'
import { ControlContainer, NgForm } from '@angular/forms'
import { ApplicationForm } from '../../../interfaces/ApplicationForm'

@Component({
  selector: 'app-address',
  template: `
    <ng-container
      [ngModelGroup]="
        isResidenceAddress ? 'residenceAddress' : 'correspondenceAddress'
      "
    >
      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Kod pocztowy</label>
        </div>
        <div class="col-md-8">
          <input
            #postcode="ngModel"
            [name]="n.postcode"
            [ngModel]="addressData?.postcode"
            mask="00-000"
            [dropSpecialCharacters]="false"
            class="loan-form__field loan-form__field-post-code loan-form__field-name-small"
            (change)="runUpdate(n.postcode, postcode.value)"
          />
          <span class="loan-form__icons loan-form__icons-special-160"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wpisz kod pocztowy</span></span
          >
        </div>
      </div>

      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Miejscowość</label>
        </div>
        <div class="col-md-8">
          <input
            #city="ngModel"
            [name]="n.city"
            [ngModel]="addressData?.city"
            class="loan-form__field loan-form__field-miasto"
            [pattern]="'^[a-zA-Z \\-\\']$'"
            (change)="runUpdate(n.city, city.value)"
          />
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wpisz miejscowość</span></span
          >
        </div>
      </div>

      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Ulica</label>
        </div>
        <div class="col-md-8">
          <input
            #street="ngModel"
            [name]="n.street"
            [ngModel]="addressData?.street"
            class="loan-form__field loan-form__field-ulica valid"
            (change)="runUpdate(n.street, street.value)"
          />
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wpisz ulicę</span></span
          >
        </div>
      </div>

      <div
        (window:resize)="onResize($event)"
        class="row d-flex align-items-center formularz-sekcja"
      >
        <div class="col-md-4">
          <label class="formularz-sekcja__label">{{
            smallDevice ? 'Numer budynku ' : 'Numer budynku / lokalu'
          }}</label>
        </div>
        <div [ngClass]="smallDevice ? '' : 'pr-0'" class="col-md-3">
          <input
            #number_building="ngModel"
            [name]="n.number_building"
            [ngModel]="addressData?.number_building"
            class="loan-form__field loan-form__field-adres loan-form__field-name-small"
            (change)="runUpdate(n.number_building, number_building.value)"
          />
          <span class="loan-form__icons loan-form__icons-special"></span>

          <label
            [ngClass]="smallDevice ? '' : 'label-separator'"
            class="formularz-sekcja__label "
          >
            {{ smallDevice ? 'Numer lokalu ' : '/' }}
          </label>
        </div>

        <div [ngClass]="smallDevice ? '' : 'pl-0'" class="col-md-3 ">
          <input
            #number_apartment="ngModel"
            [name]="n.number_apartment"
            [ngModel]="addressData?.number_apartment"
            class="loan-form__field loan-form__field-adres loan-form__field-name-small"
            (change)="runUpdate(n.number_apartment, number_apartment.value)"
          />
          <span class="loan-form__icons loan-form__icons-special-2"></span>
          <span id="tooltip-box-special" class="tooltip-box "
            >?<span class="tooltip-box__text"
              >Wpisz nr budynku/lokalu</span
            ></span
          >
        </div>
      </div>
    </ng-container>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styles: [
    `
      .loan-form__icons-special {
        right: 60px !important;
      }
      .loan-form__icons-special-2 {
        right: 75px !important;
      }
      .loan-form__icons-special-3 {
        right: 90px !important;
      }
      .loan-form__icons-special-160 {
        right: 160px !important;
      }
      .label-separator {
        padding-left: 22px;
        font-size: 16px;
      }
      #tooltip-box-special {
        right: 18px !important;
      }
      .select-css {
        padding: 12px 10px 10px 25px !important;
        font-size: 1.5em !important;
      }
    `,
  ],
})
export class AddressComponent implements OnInit, ApplicationForm {
  @Input() addressData: Address = null
  @Input() isResidenceAddress: boolean = true
  @Output() updateData = new EventEmitter<ApplicationUpdateData>()

  @ViewChild('numberBuilding') numberBuilding: HTMLDivElement

  smallDevice = false
  prefix = 'residence_'
  n: Address = {
    postcode: `${this.prefix}postcode`,
    city: `${this.prefix}city`,
    street: `${this.prefix}street`,
    number_building: `${this.prefix}number_building`,
    number_apartment: `${this.prefix}number_apartment`,
  }

  runUpdate(fieldName, fieldValue): void {
    this.updateData.emit({ fieldName, fieldValue })
  }

  constructor() {}

  ngOnInit(): void {
    this.setInputName()
    const innerWidth = window.innerWidth
    this.smallDevice = innerWidth <= 762
  }

  setInputName(): void {
    this.prefix = this.isResidenceAddress ? 'residence_' : 'correspondence_'

    this.n = {
      postcode: `${this.prefix}postcode`,
      city: `${this.prefix}city`,
      street: `${this.prefix}street`,
      number_building: `${this.prefix}number_building`,
      number_apartment: `${this.prefix}number_apartment`,
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    const width = event.target.innerWidth
    this.smallDevice = width <= 762
  }
}
