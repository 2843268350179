import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { ViewFormComponent } from './view/view-form.component'
import { ViewSuccessComponent } from './view/view-success.component'
import { ViewNotFoundComponent } from './view/view-not-found.component'
import { ViewSuccessAfterWorkComponent } from './view/view-success-after-work.component'
import { ViewProposalRefusalComponent } from './view/view-proposal-refusal.component'

const routes: Routes = [
  { path: 'wniosek', component: ViewFormComponent },
  { path: 'wniosek/:hash', component: ViewFormComponent },
  { path: 'wniosek-akceptacja', component: ViewSuccessComponent },
  {
    path: 'wniosek-akceptacja-po-godzinach',
    component: ViewSuccessAfterWorkComponent,
  },
  { path: 'wniosek-odmowa', component: ViewProposalRefusalComponent },
  { path: '**', component: ViewNotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
