import { Dictionary } from '../model/ProposalView'

export const DictionaryEducation: Array<Dictionary> = [
  { key: 1, value: ' Podstawowe' },
  { key: 2, value: ' Zawodowe' },
  { key: 3, value: ' Średnie' },
  { key: 5, value: ' Gimnazjalne' },
  { key: 6, value: ' Średnie policealne' },
  { key: 7, value: ' Wyższe licencjat' },
  { key: 8, value: ' Wyższe magisterskie' },
]
export const DictionaryMaritalStatus: Array<Dictionary> = [
  { key: 1, value: ' Panna/Kawaler' },
  { key: 2, value: ' Mężatka/Żonaty' },
  { key: 3, value: ' Wolny (Rozwiedziona/ny)' },
  { key: 4, value: ' Wdowa/Wdowiec' },
  { key: 6, value: ' Konkubina/Konkubent' },
]

export const DictionaryTypeIncome: Array<Dictionary> = [
  { key: 1, value: ' Umowa o pracę' },
  { key: 3, value: ' Emerytura' },
  { key: 4, value: ' Umowa zlecenie' },
  { key: 5, value: ' Umowa o dzieło' },
  { key: 7, value: ' Kontrakt managerski' },
  { key: 8, value: ' Działalność gospodarcza' },
  { key: 9, value: ' Gospodarstwo rolne' },
  { key: 10, value: ' Renta inwalidzka' },
  { key: 12, value: ' Renta szkoleniowa' },
  { key: 13, value: ' Renta socjalna' },
  { key: 14, value: ' Renta rodzinna' },
  { key: 15, value: ' Zasiłek' },
  { key: 18, value: ' Alimenty' },
  { key: 21, value: ' Renta strukturalna' },
  { key: 22, value: ' Powołanie/Mianowanie/Wybór' },
  { key: 23, value: ' Dochód z praw majątkowych' },
  { key: 24, value: ' Świadczenia przedemerytalne' },
  { key: 25, value: ' Spółdzielcza umowa o pracę' },
  { key: 26, value: ' Dopłaty bezpośrednie' },
  { key: 29, value: ' Urlop górniczy' },
  { key: 30, value: ' Urlop kolejowy' },
  { key: 32, value: ' Zasiłek dla bezrobotnych' },
]

export const DictionaryJobPosition: Array<Dictionary> = [
  { key: 1, value: 'Naczelnik/Kierownik/Brygadzista' },
  { key: 2, value: 'Listonosz/Kurier' },
  { key: 3, value: 'Sprzedawca' },
  { key: 4, value: 'Policjant/Strażak/Żołnierz/Celnik' },
  { key: 5, value: 'Rzemieślnik' },
  { key: 6, value: 'Kierowca/Maszynista/Motorniczy' },
  { key: 7, value: 'Nauczyciel/Wychowawca' },
  { key: 8, value: 'Inspektor' },
  { key: 9, value: 'Operator maszyn' },
  { key: 10, value: 'Strażnik/Ochroniarz' },
  { key: 11, value: 'Pracownik fizyczny' },
  { key: 12, value: 'Pielęgniarka/Salowa/Położna/Sanitariusz' },
  { key: 13, value: 'Monter' },
  { key: 14, value: 'Magazynier/Sortowacz/Pakowacz' },
  { key: 15, value: 'Referent' },
  { key: 16, value: 'Specjalista' },
  { key: 17, value: 'Budowlaniec' },
  { key: 18, value: 'Sprzątaczka' },
  { key: 19, value: 'Szwaczka/Prasowaczka/Krojczy/Krawiec' },
  { key: 20, value: 'Pracownik produkcji' },
  { key: 21, value: 'Mechanik/Lakiernik/Blacharz' },
  { key: 22, value: 'Kontroler' },
  { key: 23, value: 'Radca' },
  { key: 24, value: 'Dyrektor/w-ce Dyrektor' },
  { key: 25, value: 'Opiekunka/Pokojówka/Pomoc kuchenna' },
  { key: 26, value: 'Kucharz' },
  { key: 27, value: 'Pracownik cywilny/Socjalny' },
  { key: 28, value: 'Maszynista/Motorniczy' },
  { key: 29, value: 'Kasjer/Ekspedientka' },
  { key: 30, value: 'Piekarz' },
  { key: 31, value: 'Pracownik umysłowy' },
  { key: 32, value: 'Pracownik obsługi' },
  { key: 33, value: 'Ratownik' },
  { key: 34, value: 'Pracownik gospodarczy' },
  { key: 35, value: 'Pracownik produkcji zwierzęcej' },
  { key: 36, value: 'Palacz' },
  { key: 37, value: 'Tokarz' },
  { key: 38, value: 'Przedstawiciel handlowy/Handlowiec' },
  { key: 39, value: 'Dozorca' },
  { key: 40, value: 'Sekretarka/Asystent' },
  { key: 41, value: 'Elektryk' },
  { key: 42, value: 'Górnik/Hutnik' },
  { key: 43, value: 'Malarz' },
  { key: 44, value: 'Informatyk' },
  { key: 45, value: 'Recepcjonista/Portier/Szatniarz' },
  { key: 46, value: 'Barman/Kelner/Bufetowa' },
  { key: 47, value: 'Prezes/w-ce Prezes' },
  { key: 48, value: 'Fryzjer/Kosmetyczka' },
  { key: 49, value: 'Pracownik leśny' },
  { key: 50, value: 'Sędzia/Prokurator' },
  { key: 51, value: 'Lekarz/Psycholog' },
  { key: 52, value: 'Księgowa' },
  { key: 53, value: 'Ślusarz/Dekarz/Hydraulik/Stolarz' },
  { key: 54, value: 'Dziennikarz/Redaktor' },
  { key: 55, value: 'Ksiądz' },
  { key: 56, value: 'Farmaceuta' },
  { key: 57, value: 'Konsultant/Analityk' },
  { key: 58, value: 'Radca prawny/Adwokat' },
  { key: 59, value: 'Agent ubezpieczeniowy/Doradca finansowy' },
  { key: 60, value: 'Aktor' },
  { key: 61, value: 'Inżynier' },
  { key: 62, value: 'Agent ochrony' },
  { key: 63, value: 'Urzędnik' },
  { key: 64, value: 'Muzyk/Kompozytor' },
  { key: 65, value: 'Ogrodnik' },
  { key: 66, value: 'Zegarmistrz' },
  { key: 67, value: 'Fotograf' },
  { key: 68, value: 'Architekt' },
  { key: 69, value: 'Technik dentystyczny' },
  { key: 70, value: 'Marynarz' },
  { key: 71, value: 'Rejestratorka/Pomoc medyczna/Sekretarka medyczna' },
]

export enum TypeIncomeEnum {
  'Umowa_o_pracę' = 1,
  'Emerytura' = 3,
  'Umowa_zlecenie' = 4,
  'Umowa_o_dzieło' = 5,
  'Kontrakt_managerski' = 7,
  'Działalność_gospodarcza' = 8,
  'Gospodarstwo_rolne' = 9,
  'Renta_inwalidzka' = 10,
  'Renta_szkoleniowa' = 12,
  'Renta_socjalna' = 13,
  'Renta_rodzinna' = 14,
  'Zasiłek' = 15,
  'Alimenty' = 18,
  'Renta_strukturalna' = 21,
  'Powołanie-Mianowanie-Wybór' = 22,
  'Dochód_z_praw_majątkowych' = 23,
  'Świadczenia_przedemerytalne' = 24,
  'Spółdzielcza_umowa_o_pracę' = 25,
  'Dopłaty_bezpośrednie' = 26,
  'Urlop_górniczy' = 29,
  'Urlop_kolejowy' = 30,
  'Zasiłek_dla_bezrobotnych' = 32,
}
