import { Component, OnInit } from '@angular/core'
import { Step } from '../components/process.component'

@Component({
  selector: 'app-view-success-after-work',
  template: ` <app-header></app-header>
    <app-process [step]="step"></app-process>
    <app-decision>
      <h3 class="formularz-h3">Mamy świetną wiadomość!</h3>
      <p class="formularz-information">
        Twój wniosek przeszedł
        <span class="accent__green">pozytywną</span>
        weryfikację.<br /><br />Nasz Doradca zadzwoni w celu wypłaty pożyczki w
        godzinach pracy naszej firmy: <br />8.00-20.00 (poniedziałek -
        piątek)<br />9.00-17.00 (sobota)
      </p>
    </app-decision>
    <app-footer></app-footer>`,
})
export class ViewSuccessAfterWorkComponent implements OnInit {
  step: Step = Step.THIRD
  constructor() {}

  ngOnInit(): void {}
}
