interface FooterLinks {
  title: string;
  links: Array<{
    href: string;
    title: string;
  }>;
}

export const footerLinks: Array<FooterLinks> = [
  {
    title: 'Kredyt OK',
    links: [
      {
        href: '#',
        title: 'Strona główna',
      },
      {
        href: '#',
        title: 'O nas',
      },
      {
        href: '#',
        title: 'Blog OK',
      },
      {
        href: '#',
        title: 'Aktualności',
      },
      {
        href: '#',
        title: 'Kontakt',
      },
    ],
  },
  {
    title: 'Usługi',
    links: [
      {
        href: '#',
        title: 'Pożyczka przez telefon',
      },
      {
        href: '#',
        title: 'Pożyczka online',
      },
      {
        href: '#',
        title: 'Wakacje kredytowe',
      },
    ],
  },
  {
    title: 'Informacje',
    links: [
      {
        href: '#',
        title: 'Obowiązek informacyjny ADO',
      },
      {
        href: '#',
        title: 'Zgody',
      },
      {
        href: '#',
        title: 'Regulamin',
      },
      {
        href: '#',
        title: 'Tabela Opłat i Prowizji',
      },
      {
        href: '#',
        title: 'Poltiyka prywatności',
      },
      {
        href: '#',
        title: 'Karta informacyjna dystrybutora',
      },
      {
        href: '#',
        title: 'Ubezpieczeń',
      },
    ],
  },
];
