import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ControlContainer, NgForm } from '@angular/forms'
import { ApplicationUpdateData, ClientData } from '../../../model/ProposalView'
import {
  DictionaryEducation,
  DictionaryMaritalStatus,
} from '../../../data/Dictionaries'
import * as $ from 'jquery/dist/jquery.min.js'

@Component({
  selector: 'app-client-data',
  template: `
    <ng-container
      *ngIf="clientData"
      #form="ngModelGroup"
      ngModelGroup="clientData"
    >
      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Numer dowodu osobistego</label>
        </div>
        <div class="col-md-8">
          <input
            #id_card_number="ngModel"
            type="text"
            name="id_card_number"
            [ngModel]="clientData.id_card_number"
            required
            appIdCardValidator
            mask="SSS000000"
            (change)="runUpdate('id_card_number', id_card_number.value)"
            [ngClass]="id_card_number.errors ? 'error' : ''"
            class="loan-form__field loan-form__field-dowod loan-form__field-name-small valid"
          />
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text"
              >Wpisz numer dowodu osobistego</span
            ></span
          >
          <ng-container
            *ngIf="
              id_card_number.invalid &&
              (id_card_number.dirty || id_card_number.touched)
            "
          >
            <label *ngIf="id_card_number.errors.required" class="error"
              >Pole jest wymagane</label
            >
            <label
              *ngIf="id_card_number.errors.appIdCardValidator"
              class="error"
              >Wprowadź poprawny numer</label
            >
          </ng-container>
        </div>
      </div>

      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label"
            >Ważność dowodu osobistego</label
          >
        </div>
        <div class="col-md-8">
          <input
            id="pick"
            #validity_id_card_number="ngModel"
            name="validity_id_card_number"
            [ngModel]="clientData?.validity_id_card_number"
            [disabled]="!!form?.value?.is_indefinite_id_card"
            data-provide="datepicker"
            data-date-format="yyyy-mm-dd"
            mask="0000-00-00"
            data-date-language="pl"
            class="loan-form__field loan-form__field-adres loan-form__field-name-xsmall-date"
            (focusout)="updateDate('validity_id_card_number')"
          />
          <span class="loan-form__icons d-none"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text"
              >Podaj termin ważności dowodu osobistego (dd/mm/rrrr)
            </span></span
          >
        </div>
      </div>

      <div
        class="row d-flex align-items-center justify-content-end formularz-sekcja pb-5"
      >
        <div class="col-md-8">
          <label class="checkbox">
            <input
              #is_indefinite_id_card="ngModel"
              name="indefinite_id_card"
              [ngModel]="clientData?.indefinite_id_card"
              type="checkbox"
              (change)="
                runUpdate(
                  'indefinite_id_card',
                  is_indefinite_id_card.value ? '1' : '0'
                )
              "
            />
            <span></span>
            Zaznacz jeśli dowód jest ważny bezterminowo
          </label>
        </div>
      </div>

      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">E-mail</label>
        </div>
        <div class="col-md-8">
          <input
            #email="ngModel"
            type="email"
            name="email"
            email
            required
            [ngModel]="clientData?.email"
            [ngClass]="
              email.invalid && (email.dirty || email.touched) ? 'error' : ''
            "
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
            class="loan-form__field loan-form__field-email"
            (change)="runUpdate('email', email.value)"
          />
          <span *ngIf="form.value?.email" class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wpisz adres e-mail</span></span
          >

          <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
            <label *ngIf="email.errors.required" class="error"
              >Pole jest wymagane</label
            >
            <label *ngIf="email.errors.pattern" class="error"
              >Wprowadź poprawny adres email</label
            >
          </ng-container>
        </div>
      </div>

      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Wykształcenie</label>
        </div>
        <div class="col-md-8">
          <select
            #education="ngModel"
            name="education"
            [ngModel]="clientData?.education?.key"
            class="select-css"
            required
            [ngClass]="education.errors ? 'error' : ''"
            (change)="runUpdate('education', education.value)"
          >
            <option
              *ngFor="let education of educationDictionary"
              [value]="education.key"
              [ngValue]=""
              [selected]="education === clientData?.education"
            >
              {{ education?.value }}
            </option>
          </select>
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wybierz wykształcenie</span></span
          >

          <ng-container
            *ngIf="education.invalid && (education.dirty || education.touched)"
          >
            <label *ngIf="education.errors.required" class="error"
              >Pole jest wymagane</label
            >
          </ng-container>
        </div>
      </div>

      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Stan cywilny</label>
        </div>
        <div class="col-md-8">
          <select
            #marital_status="ngModel"
            name="marital_status"
            [ngModel]="clientData.marital_status?.key"
            class="select-css"
            (change)="runUpdate('marital_status', marital_status.value)"
          >
            <option
              *ngFor="let maritalStatus of maritalStatusDictionary"
              [selected]="maritalStatus.key === clientData?.marital_status?.key"
              [value]="maritalStatus.key"
            >
              {{ maritalStatus.value }}
            </option>
          </select>
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wybierz stan cywiliny</span></span
          >
        </div>
      </div>
    </ng-container>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ClientDataComponent implements OnInit {
  @Input() clientData: ClientData = null
  @Output() updateData = new EventEmitter<ApplicationUpdateData>()
  @ViewChild('validity_id_card_number') validityIdCardNumber

  @ViewChild('datePicker') datePicker

  viewChild
  educationDictionary = DictionaryEducation
  maritalStatusDictionary = DictionaryMaritalStatus
  constructor() {}

  ngOnInit(): void {}

  runUpdate(fieldName, fieldValue): void {
    this.updateData.emit({ fieldName, fieldValue })
  }

  updateDate(fieldName): void {
    const fieldValue = $('#pick').val()
    this.updateData.emit({ fieldName, fieldValue })
    this.validityIdCardNumber.value = fieldValue
  }
}
