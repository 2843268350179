import { Component, Input, OnInit } from '@angular/core'
import {
  Address,
  ProposalView,
  ApplicationUpdateData,
} from '../../model/ProposalView'
import { ProposalService } from '../../services/proposal.service'
import { ProposalForm } from '../../model/ProposalForm'
import { ProposalManagement } from '../../classes/ProposalManagement'
import { DecisionEnum } from '../../model/ProposalDecision'
import { Router } from '@angular/router'

@Component({
  selector: 'app-application-form',
  template: `
    <h3 class="formularz-sekcja__heading">WYPEŁNIJ FORMULARZ</h3>
    <aside class="loan-form">
      <form
        (ngSubmit)="sendApplication(form.value, form.valid)"
        #form="ngForm"
        novalidate
      >
        <h4 class="formularz-sekcja__heading-small">Dane KLIENTA</h4>
        <app-client-data
          (updateData)="updateData($event)"
          [clientData]="inputForm?.clientData"
        ></app-client-data>
        <h4 class="formularz-sekcja__heading-small">Dane Adresowe</h4>
        <app-address
          (updateData)="updateData($event)"
          [addressData]="inputForm?.residenceAddress"
          [isResidenceAddress]="true"
        ></app-address>
        <h4 class="formularz-sekcja__heading-small">Adres korespondencyjny</h4>
        <div class="row d-flex align-items-center formularz-sekcja">
          <div class="col-md-12">
            <label class="checkbox">
              <input
                #correspondence_same_residence="ngModel"
                name="correspondence_same_residence"
                [ngModel]="inputForm?.correspondence_same_residence"
                type="checkbox"
                (change)="
                  updateData({
                    fieldName: 'correspondence_same_residence',
                    fieldValue: correspondence_same_residence.value ? '1' : '0'
                  })
                "
              />
              <span></span>
              Taki sam jak powyżej
            </label>
            <span class="loan-form__icons"></span>
          </div>
        </div>

        <app-address
          *ngIf="!form.value.correspondence_same_residence"
          [addressData]="inputForm?.correspondenceAddress"
          [isResidenceAddress]="false"
          (updateData)="updateData($event)"
        ></app-address>

        <h4 class="formularz-sekcja__heading-small">Dane dochodowe</h4>

        <app-income
          (updateData)="updateData($event)"
          [income]="inputForm?.income"
          [isAdditionalIncome]="false"
        ></app-income>

        <div class="row d-flex align-items-center formularz-sekcja py-5">
          <div class="col-md-12">
            <label class="checkbox">
              <input
                #is_additional_income="ngModel"
                name="is_additional_income"
                [ngModel]="inputForm?.is_additional_income"
                type="checkbox"
                (change)="
                  updateData({
                    fieldName: 'is_additional_income',
                    fieldValue: is_additional_income.value ? '1' : '0'
                  })
                "
              />
              <span></span>
              Dodatkowe źródło dochodu
            </label>
            <span class="loan-form__icons"></span>
          </div>
        </div>

        <app-income
          (updateData)="updateData($event)"
          *ngIf="form.value.is_additional_income"
          [income]="inputForm?.additionalIncome"
          [isAdditionalIncome]="true"
        ></app-income>

        <h4 class="formularz-sekcja__heading-small">Wydatki</h4>

        <app-expenses
          (updateData)="updateData($event)"
          [expenses]="inputForm?.expenses"
        ></app-expenses>

        <div class="row justify-content-end pt-5">
          <div class="col-md-4 order-2 order-md-1">
            <button
              (click)="backToFirstStep()"
              class="standard__button loan-form__button loan-form__button-back"
            >
              Wstecz
            </button>
          </div>

          <div class="col-md-8 order-1 order-md-2">
            <button
              class="standard__button standard__button--arrow loan-form__button"
              type="submit"
            >
              Wyślij wniosek na
              <span class="loan-form__button--amount">1500</span> zł
              <img
                src="assets/images/btn-arrow.svg"
                class="standard__button--arrow-image"
              />
            </button>
          </div>
        </div>
      </form>
    </aside>
  `,
  styleUrls: ['./application-form.component.css'],
})
export class ApplicationFormComponent {
  @Input() inputForm: ProposalView

  constructor(
    private proposalService: ProposalService,
    private router: Router
  ) {}

  updateData(data: ApplicationUpdateData): void {
    this.proposalService.updateProposal(data).subscribe(
      (next) => {
        console.log(next)
      },
      (error) => console.log(error)
    )
  }

  sendApplication(value: ProposalForm, valid): void {
    this.proposalService
      .sendApplication(
        ProposalManagement.transformProposalFormToProposalApi(
          value,
          this.inputForm?.client
        )
      )
      .subscribe((next) => {
        switch (next.decision) {
          case DecisionEnum.OFFER:
            return this.router.navigate(['wniosek-akceptacja'])
          case DecisionEnum.OFFER_AFTER_WORK_HOURS:
            return this.router.navigate(['wniosek-akceptacja-po-godzinach'])
          case DecisionEnum.REFUSAL:
            return this.router.navigate(['wniosek-odmowa'])
        }
      })
  }

  backToFirstStep(): void {
    this.proposalService.redirectToFirstStep()
  }
}
