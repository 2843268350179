import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <app-footer-bottom-navs></app-footer-bottom-navs>
    <app-footer-information></app-footer-information>
  `,
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
