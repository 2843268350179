import { Directive } from '@angular/core'
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms'

@Directive({
  selector: '[appIdCardValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: IdCardValidatorDirective,
      multi: true,
    },
  ],
})
export class IdCardValidatorDirective {
  private letterValues = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    const isCorrect = this.validateIdNumber(control.value)

    return isCorrect ? null : { appIdCardValidator: true }
  }

  private validateIdNumber(idCodeNumber: string): boolean {
    if (idCodeNumber === null || idCodeNumber.length !== 9) {
      return false
    }
    idCodeNumber = idCodeNumber.toUpperCase()

    // Check serial number
    for (let i = 0; i < 3; i++) {
      if (
        this.getLetterValue(idCodeNumber[i]) < 10 ||
        idCodeNumber[i] === 'O' ||
        idCodeNumber === 'Q'
      ) {
        return false
      }
    }

    // Check numbers
    for (let i = 3; i < 9; i++) {
      if (
        this.getLetterValue(idCodeNumber[i]) < 0 ||
        this.getLetterValue(idCodeNumber[i]) > 9
      ) {
        return false
      }
    }

    let sum =
      7 * this.getLetterValue(idCodeNumber[0]) +
      3 * this.getLetterValue(idCodeNumber[1]) +
      this.getLetterValue(idCodeNumber[2]) +
      7 * this.getLetterValue(idCodeNumber[4]) +
      3 * this.getLetterValue(idCodeNumber[5]) +
      this.getLetterValue(idCodeNumber[6]) +
      7 * this.getLetterValue(idCodeNumber[7]) +
      3 * this.getLetterValue(idCodeNumber[8])
    sum %= 10

    return sum === this.getLetterValue(idCodeNumber[3])
  }

  private getLetterValue(letter): number {
    return this.letterValues.indexOf(letter)
  }
}
