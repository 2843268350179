import { Component, Input, OnInit } from '@angular/core'

export enum Step {
  'FIRST',
  'SECOND',
  'THIRD',
  'FAIL',
}

@Component({
  selector: 'app-process',
  template: ` <div class="container">
    <div class="row mt-5 pt-5">
      <div class="col-12">
        <h2 class="standard__title reliable-company__title">
          Wnioskujesz o <span class="accent__red">1500 zł</span>
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div [ngClass]="stepDom?.div?.one" class="klocek  ">
          <h5 class="formularz-process__heading">Twój wniosek online</h5>
          <img [src]="stepDom?.img?.one" alt="krok wniosku" />
        </div>
      </div>
      <div class="col-4">
        <div [ngClass]="stepDom?.div?.two" class="klocek">
          <h5 class="formularz-process__heading">Weryfikacja</h5>
          <img [src]="stepDom?.img?.two" alt="krok wniosku" />
        </div>
      </div>
      <div class="col-4">
        <div [ngClass]="stepDom?.div?.three" class="klocek">
          <h5 class="formularz-process__heading">decyzja</h5>
          <img [src]="stepDom?.img?.three" alt="krok wniosku" />
        </div>
      </div>
    </div>
  </div>`,
})
export class ProcessComponent implements OnInit {
  private rectangleRed = 'assets/images/klocek-red.png'
  private rectangleGrey = 'assets/images/klocek-grey.png'
  private rectangleBig = 'assets/images/klocek.png'
  private rectangleFail = 'assets/images/klocek-fail.png'

  stepDom = {
    img: {
      one: '',
      two: '',
      three: '',
    },
    div: {
      one: '',
      two: '',
      three: '',
    },
  }

  @Input() step: Step = Step.FIRST

  constructor() {}

  ngOnInit(): void {
    switch (this.step) {
      case Step.FIRST:
        this.setStepDom(
          [this.rectangleBig, this.rectangleGrey, this.rectangleGrey],
          ['klocek-process', 'klocek-grey', 'klocek-grey']
        )
        break
      case Step.SECOND:
        this.setStepDom(
          [this.rectangleRed, this.rectangleBig, this.rectangleGrey],
          ['klocek-grey klocek-process__step', 'klocek-process', 'klocek-grey']
        )
        break
      case Step.THIRD:
        this.setStepDom(
          [this.rectangleRed, this.rectangleRed, this.rectangleBig],
          [
            'klocek-grey klocek-process__step',
            'klocek-grey klocek-process__step',
            'klocek-process',
          ]
        )
        break
      case Step.FAIL:
        this.setStepDom(
          [this.rectangleRed, this.rectangleRed, this.rectangleFail],
          [
            'klocek-grey klocek-process__step',
            'klocek-grey klocek-process__step',
            'klocek-process',
          ]
        )
        break
    }
  }

  setStepDom(
    img: [string, string, string],
    div: [string, string, string]
  ): void {
    this.stepDom = {
      img: {
        one: img[0],
        two: img[1],
        three: img[2],
      },
      div: {
        one: div[0],
        two: div[1],
        three: div[2],
      },
    }
  }
}
