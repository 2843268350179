import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div class="formularz-loader">
      <div class="formularz-loader__box">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <h3 class="formularz-h3 text-white">SPRAWDZAMY TWOJĄ OFERTĘ</h3>
              <p class="formularz-information text-white">
                Prosimy o chwilę cierpliwości i nieodświeżanie okna przeglądarki
              </p>
              <img
                src="assets/images/loading-icon-red.gif"
                class="formularz-loader__gif"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class LoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
