import { Component, OnInit } from '@angular/core'
import { Step } from '../components/process.component'

@Component({
  selector: 'app-view-success',
  template: ` <app-header></app-header>
    <app-process [step]="step"></app-process>
    <app-decision>
      <h3 class="formularz-h3">Mamy świetną wiadomość!</h3>
      <p class="formularz-information">
        Twój wniosek przeszedł
        <span class="accent__green">pozytywną</span>
        weryfikację.<br />Nasz Doradca zadzwoni za chwilę w celu wypłaty
        pożyczki!
      </p></app-decision
    >
    <app-footer></app-footer>`,
})
export class ViewSuccessComponent implements OnInit {
  step: Step = Step.THIRD
  constructor() {}

  ngOnInit(): void {}
}
