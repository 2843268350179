import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ControlContainer, NgForm } from '@angular/forms'
import { ApplicationUpdateData, Expenses } from '../../../model/ProposalView'

@Component({
  selector: 'app-expenses',
  template: `
    <ng-container ngModelGroup="expenses">
      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label"
            >Miesięczne zobowiązania kredytowe</label
          >
        </div>
        <div class="col-md-8">
          <input
            #monthly_loan_commitments="ngModel"
            name="monthly_loan_commitments"
            [ngModel]="expenses?.monthly_loan_commitments"
            [allowNegativeNumbers]="true"
            mask="separator.2"
            class="loan-form__field loan-form__field-zkredytowe loan-form__field-pln"
            (change)="
              runUpdate(
                'monthly_loan_commitments',
                monthly_loan_commitments.value
              )
            "
          />
          <span class="loan-form__icons"></span>
          <span class="loan-form__icons-pln">zł</span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wpisz sumę swoich rat</span></span
          >
        </div>
      </div>
    </ng-container>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ExpensesComponent implements OnInit {
  @Input() expenses: Expenses = null
  @Output() updateData = new EventEmitter<ApplicationUpdateData>()

  constructor() {}

  runUpdate(fieldName, fieldValue): void {
    this.updateData.emit({ fieldName, fieldValue })
  }

  ngOnInit(): void {}
}
