import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { ControlContainer, NgForm } from '@angular/forms'
import {
  DictionaryJobPosition,
  DictionaryTypeIncome,
} from '../../../data/Dictionaries'
import { FieldManagement, ShowInput } from '../../../classes/FieldManagement'
import { ApplicationUpdateData, Income } from '../../../model/ProposalView'

interface IncomeInputName {
  type_income: string
  job_position: string
  amount_income: string
  payday: string
  employer_name: string
  employer_phone: string
}

@Component({
  selector: 'app-income',
  template: `
    <ng-container
      [ngModelGroup]="isAdditionalIncome ? 'additionalIncome' : 'income'"
    >
      <div class="row d-flex align-items-center formularz-sekcja">
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Typ dochodu</label>
        </div>
        <div class="col-md-8">
          <select
            #type_income="ngModel"
            [name]="n.type_income"
            (change)="setShowInput(type_income.value)"
            [ngModel]="income?.type_income?.key"
            class="select-css"
          >
            <option
              *ngFor="let incomeType of typeIncomeDictionary"
              [selected]="incomeType.key === income?.type_income?.key"
              [value]="incomeType.key"
            >
              {{ incomeType?.value }}
            </option>
          </select>
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wybierz typ dochodu</span></span
          >
        </div>
      </div>

      <div
        *ngIf="showInput?.job_position"
        class="row d-flex align-items-center formularz-sekcja"
      >
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Stanowisko</label>
        </div>
        <div class="col-md-8">
          <select
            #job_position="ngModel"
            [name]="n.job_position"
            [ngModel]="income?.job_position?.key"
            (change)="runUpdate(n.job_position, job_position.value)"
            class="select-css"
          >
            <option
              *ngFor="let jobPosition of jobPositionDictionary"
              [selected]="jobPosition.key === income?.job_position"
              [value]="jobPosition?.key"
            >
              {{ jobPosition?.value }}
            </option>
          </select>
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wybierz stanowisko</span></span
          >
        </div>
      </div>

      <div
        *ngIf="showInput?.amount_income"
        class="row d-flex align-items-center formularz-sekcja"
      >
        <div class="col-md-4">
          <label class="formularz-sekcja__label"
            >Dochody netto za ostatni miesiąc</label
          >
        </div>
        <div class="col-md-8">
          <input
            #amount_income="ngModel"
            [name]="n.amount_income"
            [ngModel]="income?.amount_income"
            [allowNegativeNumbers]="true"
            mask="separator.2"
            class="loan-form__field loan-form__field-dochodmsc loan-form__field-pln"
            (change)="runUpdate(n.amount_income, amount_income.value)"
          />
          <span class="loan-form__icons"></span>
          <span class="loan-form__icons-pln">zł</span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Wpisz kwotę dochodu</span></span
          >
        </div>
      </div>

      <div
        *ngIf="showInput?.payday"
        class="row d-flex align-items-center formularz-sekcja"
      >
        <div class="col-md-4">
          <label class="formularz-sekcja__label"
            >Termin wypłaty wynagrodzenia</label
          >
        </div>
        <div class="col-md-8">
          <input
            [name]="n.payday"
            [ngModel]="income?.payday"
            data-provide="datepicker"
            data-date-language="pl"
            class="loan-form__field loan-form__field-adres loan-form__field-name-xsmall-date"
          />
          <span class="loan-form__icons d-none"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text">Podaj termin wypłaty</span></span
          >
        </div>
      </div>

      <div
        *ngIf="showInput?.employer_name"
        class="row d-flex align-items-center formularz-sekcja"
      >
        <div class="col-md-4">
          <label class="formularz-sekcja__label">Nazwa pracodawcy</label>
        </div>
        <div class="col-md-8">
          <input
            #employer_name="ngModel"
            [name]="n.employer_name"
            [ngModel]="income?.employer_name"
            class="loan-form__field loan-form__field-pracodawca"
            (change)="runUpdate(n.employer_name, employer_name.value)"
          />
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text"
              >Wpisz nazwę pracodawcy</span
            ></span
          >
        </div>
      </div>

      <div
        *ngIf="showInput?.employer_phone"
        class="row d-flex align-items-center formularz-sekcja"
      >
        <div class="col-md-4">
          <label class="formularz-sekcja__label"
            >Nr telefonu do pracodawcy</label
          >
        </div>
        <div class="col-md-8">
          <input
            #employer_phone="ngModel"
            [name]="n.employer_phone"
            [ngModel]="income?.employer_phone"
            mask="000-000-000"
            class="loan-form__field loan-form__field-phone loan-form__field-telpracodawca loan-form__field-name-small"
            (change)="runUpdate(n.employer_phone, employer_phone.value)"
          />
          <span class="loan-form__icons"></span>
          <span class="tooltip-box"
            >?<span class="tooltip-box__text"
              >Wpisz nr telefonu pracodawcy</span
            ></span
          >
        </div>
      </div>
    </ng-container>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class IncomeComponent implements OnInit, OnChanges {
  @Input() income: Income = null
  @Input() isAdditionalIncome?: boolean = false
  @Output() updateData = new EventEmitter<ApplicationUpdateData>()

  jobPositionDictionary = DictionaryJobPosition
  typeIncomeDictionary = DictionaryTypeIncome
  showInput: ShowInput = null

  private prefix = ''
  n: IncomeInputName = null

  constructor(private fieldManagement: FieldManagement) {
    this.setInputName()
  }

  ngOnInit(): void {
    this.setInputName()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.income?.type_income?.key) {
      this.showInput = this.fieldManagement.menageInput(
        this.income?.type_income?.key
      )
    }
  }

  setShowInput(input): void {
    this.showInput = this.fieldManagement.menageInput(input)
    this.runUpdate(this.n.type_income, input)
  }

  runUpdate(fieldName, fieldValue): void {
    this.updateData.emit({ fieldName, fieldValue })
  }

  private setInputName(): void {
    this.prefix = this.isAdditionalIncome ? 'additional_' : ''

    this.n = {
      type_income: `${this.prefix}type_income`,
      job_position: `${this.prefix}job_position`,
      amount_income: `${this.prefix}amount_income`,
      payday: `${this.prefix}payday`,
      employer_name: `${this.prefix}employer_name`,
      employer_phone: `${this.prefix}employer_phone`,
    }
  }
}
