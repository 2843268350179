import { Dictionary } from '../model/ProposalView'

export class DictionaryManagement {
  static search(
    arrayDictionary: Array<Dictionary>,
    key: number
  ): Dictionary | null {
    const education = arrayDictionary.find(
      (dictionary) => dictionary.key === key
    )

    return education ? education : null
  }
}
