import { ProposalApi } from '../model/ProposalApi'
import { Address, Client, ProposalView } from '../model/ProposalView'
import { DictionaryManagement } from './DictionaryManagement'
import {
  DictionaryEducation,
  DictionaryJobPosition,
  DictionaryMaritalStatus,
  DictionaryTypeIncome,
} from '../data/Dictionaries'
import { ProposalForm } from '../model/ProposalForm'

export class ProposalManagement {
  static getProposalView(value: ProposalApi): ProposalView {
    return {
      hash: value.hash,
      correspondence_same_residence: !!value.correspondence_same_residence,
      is_additional_income: !!value.is_additional_income,
      client: value.client,
      expenses: value.expenses,
      residenceAddress: value.residenceAddress,
      correspondenceAddress: value.correspondenceAddress,
      clientData: {
        education: DictionaryManagement.search(
          DictionaryMaritalStatus,
          value.clientData.education
        ),
        marital_status: DictionaryManagement.search(
          DictionaryEducation,
          value.clientData.marital_status
        ),
        email: value.clientData.email,
        id_card_number: value.clientData.id_card_number,
        validity_id_card_number: value.clientData.validity_id_card_number,
        indefinite_id_card: !!value.clientData.indefinite_id_card,
      },
      income: {
        type_income: DictionaryManagement.search(
          DictionaryTypeIncome,
          value.income.type_income
        ),
        job_position: DictionaryManagement.search(
          DictionaryJobPosition,
          value.income.job_position
        ),
        payday: value.income.payday,
        employer_phone: value.income.employer_phone,
        employer_name: value.income.employer_name,
        amount_income: value.income.amount_income,
      },
      additionalIncome: {
        payday: value.additionalIncome.payday,
        employer_phone: value.additionalIncome.employer_phone,
        employer_name: value.additionalIncome.employer_name,
        type_income: DictionaryManagement.search(
          DictionaryTypeIncome,
          value.additionalIncome.type_income
        ),
        job_position: DictionaryManagement.search(
          DictionaryJobPosition,
          value.additionalIncome.job_position
        ),
        amount_income: value.additionalIncome.amount_income,
      },
    }
  }

  static transformProposalFormToProposalApi(
    proposalForm: ProposalForm,
    client: Client
  ): ProposalApi {
    const residenceAddress: Address = {
      city: proposalForm?.residenceAddress.residence_city,
      postcode: proposalForm?.residenceAddress.residence_postcode,
      street: proposalForm?.residenceAddress.residence_street,
      number_building: proposalForm?.residenceAddress.residence_number_building,
      number_apartment:
        proposalForm?.residenceAddress.residence_number_apartment,
    }

    const correspondenceAddress: Address = proposalForm?.correspondence_same_residence
      ? residenceAddress
      : {
          postcode:
            proposalForm?.correspondenceAddress?.correspondence_postcode,
          city: proposalForm?.correspondenceAddress?.correspondence_city,
          street: proposalForm?.correspondenceAddress?.correspondence_street,
          number_building:
            proposalForm?.correspondenceAddress?.correspondence_number_building,
          number_apartment:
            proposalForm?.correspondenceAddress
              ?.correspondence_number_apartment,
        }

    const proposalApi: ProposalApi = {
      income: {
        type_income: parseInt(proposalForm?.income?.type_income, 10),
        employer_phone: proposalForm.income.employer_phone,
        payday: proposalForm.income.payday,
        job_position: parseInt(proposalForm.income.job_position, 10),
        employer_name: proposalForm.income.employer_name,
        amount_income: proposalForm?.income?.amount_income,
      },
      is_additional_income: proposalForm?.is_additional_income,
      clientData: {
        education: proposalForm?.clientData?.education,
        marital_status: proposalForm?.clientData?.marital_status,
        email: proposalForm?.clientData?.email,
        validity_id_card_number:
          proposalForm?.clientData.validity_id_card_number,
        id_card_number: proposalForm?.clientData?.id_card_number,
        indefinite_id_card: proposalForm?.clientData?.indefinite_id_card,
      },
      expenses: proposalForm.expenses,
      client,
      residenceAddress,
      correspondence_same_residence:
        proposalForm?.correspondence_same_residence,
      correspondenceAddress,
    }

    if (proposalForm.additionalIncome) {
      proposalApi.additionalIncome = {
        type_income: parseInt(
          proposalForm?.additionalIncome?.additional_type_income,
          10
        ),
        employer_phone:
          proposalForm?.additionalIncome?.additional_employer_phone,
        payday: proposalForm?.additionalIncome?.additional_payday,
        job_position: parseInt(
          proposalForm?.additionalIncome?.additional_job_position,
          10
        ),
        employer_name: proposalForm.additionalIncome?.additional_employer_name,
        amount_income: proposalForm?.additionalIncome?.additional_amount_income,
      }
    }

    return proposalApi
  }
}
