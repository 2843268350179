import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-information',
  template: `
    <footer class="footer">
      <div class="inner">
        <a href="#" class="desktop-only footer__scroll-to-top">
          <img
            src="assets/images/arrow-top.svg"
            class="footer__scroll-to-top--image"
          />
          na górę
        </a>

        <aside class="main-information">
          <aside class="main-information__logo-wrapper">
            <a href="">
              <img
                src="assets/imgs/logo-white.svg"
                alt=""
                class="main-information__logo"
              />
            </a>
          </aside>
          <div class="main-information__item main-information__item--contact">
            <p><a href="tel:601500500">t. 601 500 500</a></p>
            <p><a href="mailto:kontakt@kredytok.pl">kredytok.pl</a></p>
          </div>
          <div class="main-information__item">
            <p>Capital Service S.A. w restrukturyzacji</p>
            <p>ul. Janusza Korczaka 73, 07-409 Ostrołęka</p>
          </div>
          <div class="main-information__item">
            <p>NIP: 758-235-17-11</p>
            <p>REGON: 14591449</p>
          </div>
        </aside>

        <article class="footer__legal-notice standard__desc">
          <p>
            WCapital Service Spółka Akcyjna w restrukturyzacji z siedzibą w
            Ostrołęce, przy ul. Janusza Korczaka 73, 07-409 Ostrołęka, wpisana
            do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w
            Białymstoku, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod
            numerem KRS 0000407127, NIP 758-235-17-11, REGON:145914495, kapitał
            zakładowy w wysokości 4 000 000 zł w całości wpłacony.
          </p>
          <p></p>
        </article>

        <article class="footer__additional_information standard__desc">
          <h3>
            KredytOK – pożyczka online, pożyczka przez telefon, pożyczka ratalna
            na dowolny cel!
          </h3>
          <p>
            Właścicielem marki KredytOK jest Capital Service S.A.. Marka od
            wielu lat wybierana jest przez Klientów poszukujących finansowania
            swoich potrzeb. Pożyczka na remont, pożyczka na wakacje, pożyczka na
            samochód lub pożyczka na wyprawkę szkolną, dzięki KredytOK Klienci
            od 1999 roku spełniają swoje małe i duże marzenia. Chwilówka online
            to szybki i wygodny sposób, z którego korzystają Polacy potrzebujący
            dostępu do gotówki. Coraz więcej klientów ma zaufanie do pożyczki
            przez Internet, którą od 2019 roku można wziąć bez wychodzenia z
            domu również w KredytOK. Dodatkowo Klient podczas całego procesu
            może liczyć na profesjonalne doradztwo konsultantów. KredytOK to
            instytucja finansowa, która na co dzień wspiera Klientów oferując im
            pożyczki ratalne online.
          </p>
          <p><br /></p>
          <h3>Szybka pożyczka online w KredytOK. Jak to działa?</h3>
          <p>
            Dzięki czytelnemu przedstawieniu oferty na naszej stronie
            internetowej, doskonale poznasz wszystkie zasady udzielania
            pożyczki. Jeśli potrzebujesz gotówki, wskaż interesującą Cię sumę i
            wypełnij szybki wniosek online. Czas od wypełnienia wniosku do
            decyzji to zaledwie kilka minut. Szybka decyzja pożyczkowa to dla
            nas priorytet, dlatego otrzymasz ją już po wypełnieniu formularza.
            Możesz również skorzystać z pożyczki przez telefon, podając swój
            numer – nasz konsultant skontaktuje się z Tobą, aby przedstawić Ci
            wszystkie produkty i możliwości. Pożyczka przez Internet, potoczni
            określana terminem chwilówka przez Internet to bardzo wygodny sposób
            na dodatkowe środki finansowe, dlatego warto spróbować również
            takiej formy pożyczania. W KredytOK stawiamy na komfort Klienta,
            dlatego oferujemy możliwość korzystania z oferty zarówno przez
            telefon, jak również w 100% online. Kredyt przez Internet i pożyczka
            online to wygodne i dostępne z każdego miejsca kraju formy
            pozyskiwania dodatkowych środków finansowych. Jest to bezpieczna i
            elastyczna oferta. Naszym klientom umożliwiamy 3 formy wypłaty:
            pożyczka na konto, pożyczka z odbiorem na poczcie, dowóz pożyczki do
            domu. Pamiętaj, że nasz Doradca pomoże Ci w ocenie, jaka pożyczka
            będzie dla Ciebie najkorzystniejsza.
          </p>
          <p>
            Kredyt przez Internet i pożyczka online to możliwości, z których
            można skorzystać w pełni bezpiecznie nie wychodząc z domu. Nasz
            doradca pomoże Ci w ocenie, jaka pożyczka będzie dla Ciebie
            najkorzystniejsza lub czy w Twoim przypadku bardziej opłaca się
            skorzystać z oferty kredytu.
          </p>
          <p><br /></p>
          <h3>
            Szybkie kredyty, szybkie pożyczki, pożyczka dla Seniora – jakie
            produkty finansowe oferuje KredytOK?
          </h3>
          <p>
            Produkt dla osób, które potrzebują ekspresowego dostępu do gotówki
            ze względu na nagłe wydatki to szybka pożyczka online. Przez krótki
            wniosek online zostawiasz swój numer telefonu, a my oddzwaniamy do
            Ciebie, aby wybrać najkorzystniejszą ofertę. Możesz również złożyć
            pełny wniosek online i otrzymać decyzję pożyczkową automatycznie.
            Pożyczka ratalna online to finansowanie nawet do 7000 zł z niską
            ratą miesięczną przez pierwsze 3 miesiące. Po terminowej spłacie
            kolejna szybka pożyczka jest już dostępna po 3 miesiącach. To
            świetne rozwiązanie dla osób lubiących niskie koszty i stały dostęp
            do gotówki. Pożyczka KredytOK wyróżnia się prostym procesem
            wnioskowania z minimum formalności. Wystarczy przygotować dowód
            osobisty i można wnioskować o pożyczkę online lub przez telefon.
            Pożyczka online umożliwia dostęp do kilku tysięcy złotych z wygodną
            niską ratą, przy minimum formalności. Pożyczka ratalna pozwoli
            zrealizować wszystkie planu nie obciążając przy tym budżetu
            domowego.
          </p>
          <p>
            W KredytOK wychodzimy z założenia, że pożyczka przez Internet nie
            zawsze jest dostępna dla potencjalnych klientów, w tym osób
            starszych. Pożyczka dla Seniora powinna być dostosowana to
            możliwości Seniorów. Dlatego w KredytOK kompletny wniosek pożyczkowy
            można złożyć przez telefon, a samą pożyczkę wypłacić wygodnie na
            poczcie. Dodatkową opcją wypłaty jest również dowóz gotówki
            bezpośrednio do miejsca zamieszkania Klienta. Skontaktuj się z nami
            pod wskazany na stronie numer, porozmawiaj z konsultantem i
            przekonaj się jak wiele możliwości oferuje KredytOK.
          </p>
          <h3>
            Szybka pożyczka, pożyczka online, chwilówka, kredyt online, pożyczka
            ratalna
          </h3>
          <p>
            Na rynku można spotkać wiele określeń opisujących produkty
            finansowe. W KredytOK kierując się komfortem Klientów mocno skupiamy
            się na 2 produktach: pożyczka ratalna online i pożyczka ratalna
            przez telefon. Kredyt online nie zawsze jest dostępny dla Klienta,
            dlatego nawet w takich sytuacjach staramy się zaproponować
            konkurencyjną ofertę. Chwilówka obecnie nie jest dostępna w naszej
            ofercie, ale bardzo dobrą alternatywą dla takiego produktu jest
            pożyczka ratalna online z niską ratą miesięczną
          </p>
        </article>
      </div>
    </footer>
  `,
})
export class FooterInformationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
