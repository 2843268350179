import { Client } from '../model/ProposalView'

const HASH = 'hash'
const CLIENT = 'client'

export class Storage {
  static getHash(): string {
    return localStorage.getItem(HASH)
  }

  static isExistHash(): boolean {
    return !!Storage.getHash()
  }

  static saveHash(hashValue: string): void {
    localStorage.setItem(HASH, hashValue)
  }

  static saveClient(client: Client): void {
    localStorage.setItem(CLIENT, JSON.stringify(client))
  }
}
