import { Component, OnInit } from '@angular/core'
import { Step } from '../components/process.component'

@Component({
  selector: 'app-view-proposal-refusal',
  template: ` <app-header></app-header>
    <app-process [step]="step"></app-process>
    <app-decision>
      <h3 class="formularz-h3">Brak oferty</h3>
      <p class="formularz-information">
        Twój Wniosek otrzymał <span class="accent__red">odmowę</span>. W tym
        momencie nie mamy dla Ciebie dostępnej oferty. Jeśli pojawi się
        możliwość udzielenia pożyczki niezwołcznie skontaktujemy się z Tobą
      </p>
    </app-decision>
    <app-footer></app-footer>`,
})
export class ViewProposalRefusalComponent implements OnInit {
  step: Step = Step.FAIL

  constructor() {}

  ngOnInit(): void {}
}
