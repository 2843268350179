import { Component, OnInit } from '@angular/core';
import { footerLinks } from '../../data/FooterLinks';

@Component({
  selector: 'app-footer-bottom-navs',
  template: ` <footer class="bottom-navs">
    <div class="inner">
      <aside *ngFor="let data of footerLinks" class="bottom-navs__nav">
        <h4 class="bottom-navs__title">{{ data.title }}</h4>
        <ul class="bottom-navs__list">
          <li *ngFor="let link of data.links" class="bottom-navs__item">
            <a href="" class="bottom-navs__link">{{ link.title }}</a>
          </li>
        </ul>
      </aside>
    </div>
  </footer>`,
})
export class FooterBottomNavsComponent {
  footerLinks = footerLinks;
}
