import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ProposalService } from '../services/proposal.service'
import { Storage } from '../classes/Storage'
import { ProposalView } from '../model/ProposalView'
import { Step } from '../components/process.component'

@Component({
  selector: 'app-view-form',
  template: `
    <app-header></app-header>
    <app-process [step]="step"></app-process>
    <section id="form-kredytok">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row justify-content-between">
              <div class="col-md-7">
                <app-application-form
                  [inputForm]="applicationFormData"
                ></app-application-form>
              </div>
              <div class="col-md-4 d-none d-md-block">
                <app-application-helper-box></app-application-helper-box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  `,
})
export class ViewFormComponent implements OnInit {
  step: Step = Step.FIRST
  applicationFormData: ProposalView = null
  constructor(
    private route: ActivatedRoute,
    private proposalService: ProposalService
  ) {}

  ngOnInit(): void {
    this.menageHash()
  }

  private menageHash(): void {
    const hash = this.route.snapshot.paramMap.get('hash')
    hash && Storage.saveHash(hash)

    Storage.isExistHash()
      ? this.getDataFromHash()
      : this.proposalService.redirectToFirstStep()
  }

  private getDataFromHash(): void {
    this.proposalService.getDataFromHash(Storage.getHash()).subscribe(
      (value) => {
        this.applicationFormData = value
        Storage.saveClient(value.client)
      },
      () => this.proposalService.redirectToFirstStep()
    )
  }
}
