import { Component, HostListener } from '@angular/core'

@Component({
  selector: 'app-header',
  template: `
    <header
      (scroll)="onScroll()"
      [class]="scrollHeaderClass"
      id="header"
      class="header header-form"
    >
      <div class="inner">
        <aside class="header__logo">
          <a href="" title="">
            <img
              src="assets/imgs/logo-color.svg"
              alt=""
              title=""
              class="header__logo--image"
            />
          </a>
        </aside>
        <aside class="header__contact-form">
          <a href="#" class="mr-4">
            <img src="assets/images/mail-form.png" alt="" title="" />
          </a>
          <a href="#">
            <img src="assets/images/phone-form.png" alt="" title="" />
          </a>
        </aside>
      </div>
    </header>
    <div class="header__offset desktop-only"></div>
  `,
})
export class HeaderComponent {
  scrollHeaderClass: string = ''
  oldValue: number = 0

  @HostListener('window:scroll')
  onScroll(): void {
    const offsetY = window.pageYOffset
    this.scrollHeaderClass =
      offsetY === 0
        ? ''
        : offsetY >= 1 && offsetY <= 145
        ? 'header--scroll'
        : 'header--scroll header--scroll-up'
    this.oldValue - offsetY > 0 && offsetY >= 145
      ? (this.scrollHeaderClass = 'header--scroll')
      : null

    this.oldValue = offsetY
  }
}
