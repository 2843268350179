export interface ProposalDecision {
  decision: DecisionEnum
}

export enum DecisionEnum {
  'OFFER' = 1,
  'OFFER_AFTER_WORK_HOURS',
  'REFUSAL',
  'API_ERROR',
  'VALIDATION_ERROR',
}
