import { TypeIncomeEnum } from '../data/Dictionaries'

export interface ShowInput {
  amount_income: boolean
  employer_name: boolean
  employer_phone: boolean
  payday: boolean
  job_position: boolean
}
export class FieldManagement {
  private showInput: ShowInput = {
    amount_income: false,
    employer_name: false,
    employer_phone: false,
    payday: false,
    job_position: false,
  }

  menageInput(elem: TypeIncomeEnum): ShowInput {
    // @ts-ignore
    elem = parseInt(elem, 10)
    switch (elem) {
      case TypeIncomeEnum.Alimenty:
      case TypeIncomeEnum.Dochód_z_praw_majątkowych:
        this.setShowInput(1, 0, 0, 0, 1)
        break
      case TypeIncomeEnum.Działalność_gospodarcza:
        this.setShowInput(1, 1, 1, 0, 0)
        break
      case TypeIncomeEnum.Emerytura:
      case TypeIncomeEnum.Renta_inwalidzka:
      case TypeIncomeEnum.Renta_rodzinna:
      case TypeIncomeEnum.Renta_socjalna:
      case TypeIncomeEnum.Renta_strukturalna:
      case TypeIncomeEnum.Renta_szkoleniowa:
      case TypeIncomeEnum.Świadczenia_przedemerytalne:
      case TypeIncomeEnum.Zasiłek:
      case TypeIncomeEnum.Zasiłek_dla_bezrobotnych:
        this.setShowInput(1, 1, 0, 0, 1)
        break
      case TypeIncomeEnum.Gospodarstwo_rolne:
      case TypeIncomeEnum.Dopłaty_bezpośrednie:
        this.setShowInput(1, 0, 0, 0, 0)
        break
      case TypeIncomeEnum.Kontrakt_managerski:
      case TypeIncomeEnum['Powołanie-Mianowanie-Wybór']:
      case TypeIncomeEnum.Spółdzielcza_umowa_o_pracę:
      case TypeIncomeEnum.Umowa_o_pracę:
      case TypeIncomeEnum.Umowa_zlecenie:
      case TypeIncomeEnum.Urlop_górniczy:
      case TypeIncomeEnum.Urlop_kolejowy:
        this.setShowInput(1, 1, 1, 1, 1)
        break
      case TypeIncomeEnum.Umowa_o_dzieło:
        this.setShowInput(1, 1, 1, 1, 0)
        break
      default:
        this.setShowInput(0, 0, 0, 0, 0)
        break
    }

    return this.showInput
  }

  private setShowInput(
    AI: number,
    EN: number,
    EP: number,
    DP: number,
    JB: number
  ): void {
    this.showInput = {
      amount_income: !!AI,
      employer_name: !!EN,
      employer_phone: !!EP,
      payday: !!DP,
      job_position: !!JB,
    }
  }
}
