import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-helper-box',
  template: `
    <aside class="header__phone">
      <p class="header__phone--label">Potrzebujesz pomocy?</p>
      <a href="#" class="header__phone-box">
        <img src="assets/images/mail-form.png" alt="" title="" />
        <strong>kontakt@kredytok.pl</strong>
      </a>
      <a href="#" class="header__phone-box">
        <img src="assets/images/phone-form.png" alt="" title="" />
        <strong>601 500 500</strong>
      </a>
    </aside>
  `,
})
export class ApplicationHelperBoxComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
