import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HeaderComponent } from './components/header.component'
import { ProcessComponent } from './components/process.component'
import { LoaderComponent } from './components/loader.component'
import { FooterComponent } from './components/footer/footer.component'
import { FooterBottomNavsComponent } from './components/footer/footer-bottom-navs.component'
import { FooterInformationComponent } from './components/footer/footer-information.component'
import { ApplicationHelperBoxComponent } from './components/application/application-helper-box.component'
import { ApplicationFormComponent } from './components/application/application-form.component'
import { FormsModule } from '@angular/forms'
import { FieldManagement } from './classes/FieldManagement'
import { AddressComponent } from './components/application/form/address.component'
import { IncomeComponent } from './components/application/form/income.component'
import { ClientDataComponent } from './components/application/form/client-data.component'
import { ExpensesComponent } from './components/application/form/expenses.component'
import { IdCardValidatorDirective } from './validators/id-card-validator.directive'
import { NgxMaskModule } from 'ngx-mask'
import { ViewFormComponent } from './view/view-form.component'
import { ViewSuccessComponent } from './view/view-success.component'
import { HttpClientModule } from '@angular/common/http'
import { ViewNotFoundComponent } from './view/view-not-found.component'
import { ViewSuccessAfterWorkComponent } from './view/view-success-after-work.component'
import { DecisionComponent } from './components/decision.component'
import { ViewProposalRefusalComponent } from './view/view-proposal-refusal.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProcessComponent,
    LoaderComponent,
    FooterComponent,
    FooterBottomNavsComponent,
    FooterInformationComponent,
    ApplicationHelperBoxComponent,
    ApplicationFormComponent,
    AddressComponent,
    IncomeComponent,
    ClientDataComponent,
    ExpensesComponent,
    IdCardValidatorDirective,
    ViewFormComponent,
    ViewSuccessComponent,
    ViewNotFoundComponent,
    ViewSuccessAfterWorkComponent,
    DecisionComponent,
    ViewProposalRefusalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
  ],
  providers: [FieldManagement],
  bootstrap: [AppComponent],
})
export class AppModule {}
