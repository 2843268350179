import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-view-not-found',
  template: ` <app-header></app-header>
    <section id="form-kredytok">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <h3 class="formularz-h3">404</h3>
            <p class="formularz-information">
              Strona
              <span class="accent__red">nie istnieje</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>`,
})
export class ViewNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
