import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-decision',
  template: `
    <section id="form-kredytok">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </section>
  `,
})
export class DecisionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
